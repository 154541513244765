import React, { useState, useEffect } from "react";
import { Table, Drawer, Select, Button, Space, Tooltip, Input } from 'antd';
import { DrawerProps } from 'antd/es/drawer';
import qr from '../images/qrcode.png';
import { UserOutlined, MoneyCollectOutlined, AppstoreAddOutlined, MinusCircleOutlined, PlayCircleOutlined, EditOutlined, PauseCircleOutlined, PhoneOutlined, GlobalOutlined, CheckCircleOutlined, RedEnvelopeOutlined, CloseCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { host } from '../config.js'
const { Option } = Select;

const { Search } = Input;



function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
}

const Users = () => {

    const [visible, setVisible] = useState(false);
    const [size, setSize] = useState("large");
    let [loading, setLoading] = useState(false);
    let [clients, setClients] = useState([]);
    let [activationVisible, setActivationVisible] = useState(false);
    let [userId, setUserId] = useState(0);
    let [editMode, setEditMode] = useState(false)
    let [filter, setFilter] = useState({ code: "" })
    let [customSearch, setCustomSearch] = useState("");
    let [sortOrder, setSortOrder] = useState("");

    let [packages, setPackages] = useState([]);

    let [activation, setActivation] = useState({
        count: "",
        price: "",
        user_id: "",
        exp_date: "",
        package_id: 0,
        payed_price: 0,
    })


    let [userInfo, setUserInfo] = useState({
        full_name: "",
        phone_number: "",
        email: "",
        gender: "Տղամարդ",
        with_trainer: "Առանց Մարզիչ",
        address: "",
        code: ""
    })


    const openActivationDrawer = (id) => {
        setSize("large");
        setActivationVisible(true);
        setUserId(id);
        activation.user_id = id;
        setActivation({ ...activation })
    }

    const openDrawer = () => {
        setEditMode(false);
        setSize('large');
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
        setActivationVisible(false);
    };

    const onSearch = value => {
        let data = { code: value };
        setFilter({ ...data });
        getClients();
    }

    const onCustomSearch = value => {
        setCustomSearch(value.trim());  // Trim to remove extra spaces
        getClients();
    };

    const onSortChange = value => {
        setSortOrder(value);
        getClients();
    };

    const getPackages = () => {
        axios.get(host + "/package?username=" + localStorage.getItem("username") + "&password=" + localStorage.getItem("password"), {}).then((data) => {
            if (data.data.error) {
                alert(data.data.message)
            } else {
                setPackages(data.data.message);
            }

        }).catch((err) => {
            alert(err.message);
            setLoading(false)
        });
    }

    const getClients = () => {
        let user = window.localStorage.getItem("username");
        let pass = window.localStorage.getItem("password");
        let url = host + "/clients?username=" + user + "&password=" + pass;
        if (filter.code !== "") {
            url += "&filter=" + JSON.stringify(filter)
        }

        if (customSearch) {
            url += `&q=${encodeURIComponent(customSearch)}`;
        }

        if (sortOrder) {
            url += `&sort=${sortOrder},desc`; // Default to descending order
        }

        axios.get(url).then((data) => {

            if (!data.data.error) {
                setClients(data.data.message);
            }

        }).catch((err) => {
            alert(err.message);
        })
    }

    const register = () => {
        axios.post(host + "/clients", {
            ...userInfo,
            username: window.localStorage.getItem("username"),
            password: window.localStorage.getItem("password")
        }).then((data) => {
            if (data.data.error) {
                alert(data.data.message);
            } else {
                getClients();
                setVisible(false);
            }
        }).catch((err) => {
            alert(err.message);
        })
    }

    const updateUser = () => {
        axios.put(host + "/clients", {
            ...userInfo,
            username: window.localStorage.getItem("username"),
            password: window.localStorage.getItem("password")
        }).then((data) => {
            if (data.data.error) {
                alert(data.data.message);
            } else {
                getClients();
                setEditMode(false)
                setVisible(false);
            }
        }).catch((err) => {
            alert(err.message);
        })
    }

    useEffect(() => {
        getClients();
        getPackages();
    }, [customSearch, sortOrder]);


    const addVisits = () => {
        let currentPackage = {}
        for (let i = 0; i < packages.length; i++) {
            if (packages[i].id === activation.package_id) {
                currentPackage = packages[i];
                break;
            }
        }

        let date = new Date();

        let value = parseInt(currentPackage.schedule_time);

        switch (currentPackage.schedule_type) {
            case "Օր":
                date.setDate(date.getDate() + value);

                break
            case "Ամիս":
                date.setMonth(date.getMonth() + value);
                break
            case "Տարի":
                date.setFullYear(date.getFullYear() + value);
                break;
            default:
                alert("Not valid date")
                break;
        }
        let newDate = new Date(date.getTime())
        activation.exp_date = newDate;
        activation.count = currentPackage.visits_count;
        activation.price = currentPackage.price;
        setActivation({ ...activation })
        axios.post(host + "/add_visits", {
            ...activation,
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password")
        }).then((data) => {
            if (data.data.error) {
                alert(data.data.message);
            } else {
                getClients();
                setActivationVisible(false);
            }

        }).catch((err) => {
            alert(err.message);
        })
    }

    const formatDate = (str) => {
        let date = new Date(str);

        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        let hours = date.getHours();
        if (hours < 10) hours = "0" + hours;

        let minutes = date.getMinutes();
        if (minutes < 10) minutes = "0" + minutes;

        return day + "/" + month + "/" + year;
    }

    const enable = async (id) => {
        await axios.put(host + "/clients/disable", {
            username: window.localStorage.getItem("username"),
            password: window.localStorage.getItem("password"),
            user_id: id
        })
        getClients();
    }

    const deleteUser = async (id) => {
        await axios.post(host + "/clients/delete", {
            username: window.localStorage.getItem("username"),
            password: window.localStorage.getItem("password"),
            id: id
        });
        getClients();
    }

    const edit = async (row) => {
        console.log(row);
        setEditMode(true);
        setUserId(row.id);
        setUserInfo({ ...row });
        setSize('large');
        setVisible(true);
    }

    const disable = async (id) => {
        await axios.put(host + "/clients/enable", {
            username: window.localStorage.getItem("username"),
            password: window.localStorage.getItem("password"),
            user_id: id
        });

        getClients();
    }

    const columns = [

        {
            title: '#',
            dataIndex: 'id',
        },
        {
            title: 'Անուն ազգանուն',
            dataIndex: 'full_name',
            key: 'full_name',
        },
        {
            title: 'Սեռ',
            dataIndex: 'gender',
            key: 'gender',

        },
        {
            title: "հեռախոսահամար",
            datIndex: "phone_number",
            key: "phone_number",
            render: (phone_number) => {
                return <a href={"tel:" + phone_number.phone_number.toString()}>{phone_number.phone_number.toString()}</a>
            }

        },
        {
            title: "ժամկետը լրացած ",
            dataIndex: "is_expired",
            key: "is_expired",
            render: (is_expired) => {
                if (is_expired) {
                    return <CloseCircleOutlined style={{ color: "red" }} color={"red"} />
                } else {
                    return <CheckCircleOutlined style={{ color: "green" }} color={"green"} />
                }
            }
        },
        {
            title: "Ընթացիկ այցելության համարը",
            dataIndex: "current_visit_number",
            key: "current_visit_number"

        },
        {
            title: "Չհաջողված այցելություններ",
            dataIndex: "last_failed_count",
            key: "last_failed_count"

        },
        {
            title: "Կարող է այցելել",
            dataIndex: "visits_count",
            key: "visits_count"
        },
        {
            title: "Այցելությունների ժամկետի ավարտը",
            dataIndex: "visits_expire_date",
            key: "visits_expire_date",
            render: (row) => {
                if (row) {
                    return formatDate(row);
                } else {
                    return "N/A"
                }
            }
        },
        {
            title: "...",
            dataIndex: "id",
            key: "id",
            render: (id, row) => {
                return (
                    <div>
                        <Tooltip onClick={() => openActivationDrawer(id)} title="Ավելացնել այցելություններ">
                            <Button type="primary" shape="circle" icon={<AppstoreAddOutlined />} />
                        </Tooltip>
                        <Tooltip onClick={() => deleteUser(id)} title="Հեռացնել օգտվողին">
                            <Button type="danger" shape="circle" icon={<MinusCircleOutlined />} />
                        </Tooltip>
                        <Tooltip onClick={() => edit(row)} title="Խմբագրել հաճախորդը">
                            <Button style={{ background: "orange" }} shape="circle" icon={<EditOutlined />} />
                        </Tooltip>
                        {row.status === 1 ?
                            <Tooltip title="Դադարեցրեք հաճախորդների աջակցությունը">
                                <Button onClick={() => enable(id)} type="danger" shape="circle" icon={<PauseCircleOutlined />} />
                            </Tooltip>
                            :
                            <Tooltip title="Ակտիվացրեք հաճախորդը">
                                <Button onClick={() => disable(id)} type="primary" shape="circle" icon={<PlayCircleOutlined />} />
                            </Tooltip>
                        }

                    </div>
                )
            }
        }
    ];


    return (
        <div>
            <h1 style={{fontSize: "32px"}}>Օգտատերեր</h1>
            <div style={{float: "left"}}>
                <Search placeholder="Որոնել կոդով" onSearch={onSearch} enterButton/>
            </div>
            <div style={{float: "left"}}>
                <Search placeholder="Որոնել (Custom)" onSearch={onCustomSearch} enterButton/>
            </div>
            <div style={{float: "left"}}>
                <Select
                    style={{width: 200}}
                    placeholder="Sort By"
                    onChange={onSortChange}
                    value={sortOrder}
                >
                    <Option value="createdAt">Ըստ գրանցման</Option>
                    <Option value="visits_expire_date">Ըստ այցերի ավարտի</Option>
                </Select>
            </div>
            <div style={{textAlign: "right", margin: "10px"}}>
                <Button type="primary" onClick={openDrawer}>
                    Ավելացնել օգտվող
                </Button>
            </div>
            <Drawer
                title={`Ավելացնել այցելություններ`}
                placement="right"
                size={size}
                onClose={onClose}
                visible={activationVisible}
            >
                <Select style={{width: "100%"}} size={"large"} onChange={(e) => {
                    activation.package_id = e;
                    setActivation({...activation})
                }} value={activation.package_id} className="select-after">
                    <Option key={0} value={0}>Ընտրեք փաթեթ</Option>
                    {packages.map((index, key) => {
                        return <Option value={index.id} key={key}>{index.name}</Option>
                    })}

                </Select>
                <div className={"form-block"}>
                    <Input size="large" value={activation.payed_price} onInput={(e) => {
                        activation.payed_price = e.target.value;
                        setActivation({...activation})
                    }} placeholder="Կանխիկ վերցված գումար" prefix={<MoneyCollectOutlined/>}/>
                </div>
                {packages.map((index, key) => {
                    if (index.id === activation.package_id) {
                        return <ul style={{
                            listStyle: "none",
                            marginLeft: "0",
                            paddingLeft: "0",
                            fontSize: "20px",
                            marginTop: "21px"
                        }}>
                            <li>Այցելություններ : {index.visits_count}</li>
                            <li>Գին : {index.price}</li>
                            <li>Տեւողությունը : {index.schedule_time + " " + index.schedule_type}</li>
                        </ul>
                    }
                })}

                <div className={"form-block"}>
                    <Button type="primary" loading={loading} onClick={addVisits}>
                        Ավելացնել
                    </Button>
                </div>
            </Drawer>

            <Drawer
                title={editMode ? `Ավելացնել օգտվող` : "Թարմացրեք օգտվողին"}
                placement="right"
                size={size}
                onClose={onClose}
                visible={visible}
            >

                <div>
                    <h1 style={{textAlign: "center"}}>
                        Ավելացնել նոր հաճախորդ
                    </h1>
                    <div className={"form-block"}>
                        <Input size="large" value={userInfo.code} onInput={(e) => {
                            userInfo.code = e.target.value;
                            setUserInfo({...userInfo})
                        }} placeholder="Code" prefix={<UserOutlined/>}/>
                    </div>
                    <div className={"form-block"}>
                        <Input size="large" value={userInfo.full_name} onInput={(e) => {
                            userInfo.full_name = e.target.value;
                            setUserInfo({...userInfo})
                        }} placeholder="Անուն ազգանուն" prefix={<UserOutlined/>}/>
                    </div>
                    <div className={"form-block"}>
                        <Input size="large" value={userInfo.phone_number} onInput={(e) => {
                            userInfo.phone_number = e.target.value;
                            setUserInfo({...userInfo});
                        }} placeholder="Հեռախոսահամար" type={"text"} prefix={<PhoneOutlined/>}/>
                    </div>

                    <div className={"form-block"}>
                        <Input size="large" value={userInfo.email} onInput={(e) => {
                            userInfo.email = e.target.value
                            setUserInfo({...userInfo})
                        }} placeholder="Էլեկտրոնային հասցե" prefix={<RedEnvelopeOutlined/>}/>
                    </div>
                    <div className={"form-block"}>
                        <Input size="large" value={userInfo.address} onInput={(e) => {
                            userInfo.address = e.target.value;
                            setUserInfo({...userInfo});
                        }} placeholder="Հասցե" type={"text"} prefix={<GlobalOutlined/>}/>
                    </div>
                    <div className={"form-block"}>


                        <Select onChange={(e) => {
                            userInfo.gender = e;
                            setUserInfo({...userInfo});
                        }} value={userInfo.gender} className="select-after">
                            <Option value="Տղամարդ">Տղամարդ</Option>
                            <Option value="Կին">Կին</Option>
                        </Select>

                    </div>
                    <div className="form-block">
                        <Select onChange={(e) => {
                            userInfo.with_trainer = e;
                            setUserInfo({...userInfo});
                        }} value={userInfo.with_trainer} className="select-after">
                            <Option value="Առանց մարզիչ">Առանց մարզիչ</Option>
                            <Option value="Մարզիչով">Մարզիչով</Option>
                        </Select>
                    </div>
                    <div className={"form-block"}>
                        {editMode ? <Button style={{background: "orange"}} type="primary" loading={loading}
                                            onClick={updateUser}>
                                Թարմացրեք
                            </Button> :

                            <Button type="primary" loading={loading} onClick={register}>
                                Գրանցել
                            </Button>
                        }

                    </div>
                </div>


            </Drawer>
            <Table columns={columns} dataSource={clients} onChange={onChange}/>
        </div>
    )
}

export default Users;
